.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.cpalsy-input {
  width: 250px;
}

.cpalsy-input:hover {
  background-color: #A9A9A9;
}


.cpalsy-hr {
  background-color: #29465B;
  font-weight: italic;
  font-size:100%;
  align-items: left;
  justify-content: center;
  color: #29465B;
}

.cpalsy-output {
  background-color: #F5E4E0;
  font-weight: bold;
  font-size:100%;
  align-items: left;
  justify-content: center;
  color: #29465B;
}

.cpalsy-outcome {
  //background-color: #F5E4E0;
  //font-weight: bold;
  font-size:100%;
  align-items: left;
  justify-content: center;
  color: #5C3317;
}

.cpalsy-outcome-green {
  background-color: #437C17;
  //font-weight: bold;
  font-size:100%;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
}

.cpalsy-outcome-yellow {
  background-color: #FFA62F;
  //font-weight: bold;
  font-size:100%;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
}

.cpalsy-outcome-red {
  background-color: #F75D59;
  //font-weight: bold;
  font-size:100%;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
